html, body, #opEditorHostRoot {
  font-family: "Gelato Sans", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

.cursorPointer {
  cursor: pointer;
}
