@import '../../comonStyles/variables';

.loader {
  border: 12px solid $lightGray;
  border-top: 12px solid $blue;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  display: inline-flex;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
