@import '../../../comonStyles/variables';

.navLink {
  color: $gray;
  padding: 0.0rem 1rem !important;

  &.active {
    color: $blue;
  }
}
