@import '../../comonStyles/variables';

.wrapper {
  display: inline-flex;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    padding: 2px 0px 0px 0px !important;
    display: inline-flex !important;
    justify-items: center;
    vertical-align: middle;
    justify-content: center;
  }

  .label {
    margin-left: 4px;
  }
}
